
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { addDevices } from "../../../store/api/devices";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface LoraData {
  variant: string;
  unique_random_number: string;
  product_count: string;
  serial_number: string;
  device_eui: string;
  application_eui: string;
  application_key: string;
  application_session_key: string;
  network_session_key: string;
  firmware_version: string;
  sensor: string;
  product_id: string;
}

export default defineComponent({
  name: "create-api-key-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    val: { type: String, required: true },
  },
  setup(prop) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const createDeviceModalRef = ref<null | HTMLElement>(null);
    const deviceRes = ref<any[]>([]);
    const picked = ref<any>("v2");
    const loraData = ref<LoraData>({
      variant: prop.val,
      unique_random_number: "",
      product_count: "",
      serial_number: "",
      device_eui: "",
      application_eui: "",
      application_key: "",
      application_session_key: "",
      network_session_key: "",
      firmware_version: "",
      sensor: "",
      product_id: "",
    });

    const validationSchema = Yup.object().shape({
      variant: Yup.string().label("Variant"),
      unique_random_number: Yup.string().required().label("Device UID"),
      product_count: Yup.string().label("Product Count"),
      serial_number: Yup.string().required().label("Serial Number"),
      device_eui: Yup.string().required().label("Device Eui"),
      application_eui: Yup.string().required().label("Application Eui"),
      application_key: Yup.string().required().label("Application Key"),
      application_session_key: Yup.string().label("Application session key"),
      network_session_key: Yup.string().label("Network session key"),
      firmware_version: Yup.string().label("Firmware version"),
      sensor: Yup.string().label("sensor Type"),
      product_id: Yup.string().label("Product Id"),
    });

    const submit = async () => {
      console.log("Data from Device Lora");

      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      addDevices(loraData.value)
        .then((res) => {
          console.log("coming res is", res);
          if (submitButtonRef.value) submitButtonRef.value.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            text: "Form has been successfully submitted!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            document.getElementById("adddevicedatamodalclose")?.click();
          });
        })
        .catch((e) => {
          console.log("err is =", e);
          deviceRes.value = e.response.data;
          if (submitButtonRef.value) submitButtonRef.value.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${deviceRes.value[0].message}`,
          });
        });
    };

    return {
      loraData,
      validationSchema,
      submit,
      deviceRes,
      submitButtonRef,
      modalRef,
      createDeviceModalRef,
      picked,
    };
  },
});
