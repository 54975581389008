
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { addDevices } from "../../../store/api/devices";
interface NbiotData {
  variant: string;
  variant_id: string;
  unique_random_number: string;
  product_count: string;
  serial_number: string;
  firmware_version: string;
  sensor: string;
  icc: string;
  antenna: string;
  batter: string;
  gasket: string;
  screws: string;
  conformal_coating: string;
  silicon_between_lid_and_housing: string;
  payload_specification_document_version: string;
  sealant_on_tunnel: string;
  casing_batch_number: string;
  product_id: string;
}

export default defineComponent({
  name: "create-api-key-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    val: {
      type: String,
      required: true,
    },
  },
  setup(prop) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    const deviceRes = ref<any[]>([]);
    const picked = ref<any>("v2");

    const nbiotData = ref<NbiotData>({
      variant: prop.val,
      variant_id: "",
      unique_random_number: "",
      product_count: "",
      serial_number: "",
      firmware_version: "",
      sensor: "",
      icc: "",
      antenna: "",
      batter: "",
      gasket: "",
      screws: "",
      conformal_coating: "",
      silicon_between_lid_and_housing: "",
      payload_specification_document_version: "",
      sealant_on_tunnel: "",
      casing_batch_number: "",
      product_id: "",
    });

    const validationSchema = Yup.object().shape({
      variant: Yup.string().label("Variant"),
      variant_id: Yup.string().required().label("Imei"),
      unique_random_number: Yup.string().required().label("Device UID"),
      product_count: Yup.string().label("Product Count"),
      serial_number: Yup.string().required().label("Serial Number"),
      firmware_version: Yup.string().label("Firmware Version"),
      sensor: Yup.string().label("Sensor Type"),
      icc: Yup.string().required().label("ICCID"),
      antenna: Yup.string().label("antenna"),
      batter: Yup.string().label("batter"),
      gasket: Yup.string().label("Gasket"),
      screws: Yup.string().label("Screws"),
      conformal_coating: Yup.string().label("Conformal Coating"),
      silicon_between_lid_and_housing: Yup.string().label(
        "Silicon Between lid and housing"
      ),
      payload_specification_document_version:
        Yup.string().label("Payload version"),
      sealant_on_tunnel: Yup.string().label("Sealant On Tunel"),
      casing_batch_number: Yup.string().label("Casing Batch Number"),
      product_id: Yup.string().label("Product Id"),
    });

    const submit = () => {
      console.log("Data from Device NbIoT");
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      nbiotData.value["imei"] = nbiotData.value.variant_id;
      addDevices(nbiotData.value)
        .then((res) => {
          if (submitButtonRef.value) submitButtonRef.value.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            text: `Successfully device add and success is ${res.success}`,
            icon: "success",
            buttonStyling: false,
            confirmButtonText: "OK",
          });
        })
        .catch((e) => {
          deviceRes.value = e.response.data;
          if (submitButtonRef.value) submitButtonRef.value.disabled = false;

          // Activate indicator
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${deviceRes.value[0].message}`,
          });
        });
    };

    return {
      nbiotData,
      validationSchema,
      submit,
      submitButtonRef,
      modalRef,
      createAPIKeyModalRef,
      picked,
    };
  },
});
