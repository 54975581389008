
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { addDevices } from "../../../store/api/devices";
interface SigfoxData {
  variant: string;
  variant_id: string;
  pac_id: string;
  product_count: string;
  serial_number: string;
  product_id: string;
  unique_random_number: string;
  sensor: string;
}

export default defineComponent({
  name: "create-api-key-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    val: { type: String, required: true },
  },
  setup(prop) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const deviceRes = ref<any[]>([]);
    const sigfoxData = ref<SigfoxData>({
      variant: prop.val,
      variant_id: "",
      pac_id: "",
      product_count: "",
      serial_number: "",
      product_id: "",
      unique_random_number: "",
      sensor: "",
    });

    const validationSchema = Yup.object().shape({
      variant: Yup.string().label("Variant"),
      variant_id: Yup.string().required().label("Variant Id"),
      pac_id: Yup.string().required().label("Pack Id"),
      product_count: Yup.string().label("Product Count"),
      serial_number: Yup.string().required().label("Serial Number"),
      product_id: Yup.string().label("Product Id"),
      unique_random_number: Yup.string().required().label("Device UID"),
      sensor: Yup.string().label("Sensor Type"),
    });
    const submit = async () => {
      console.log("Data from Device Sigfox");
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button

      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      sigfoxData.value["sigfox_id"] = sigfoxData.value.variant_id;
      // sigfoxData.value["pac_id"] = sigfoxData.value.variant_id;

      addDevices(sigfoxData.value)
        .then((res) => {
          console.log(res);
          if (submitButtonRef.value) submitButtonRef.value.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            text: "Form has been successfully submitted!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        })
        .catch((e) => {
          console.log("err", e);
          deviceRes.value = e.response.data;
          if (submitButtonRef.value) submitButtonRef.value.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${deviceRes.value[0].message}`,
          });
        });
    };

    return {
      sigfoxData,
      deviceRes,
      validationSchema,
      submit,
      submitButtonRef,
      modalRef,
    };
  },
});
