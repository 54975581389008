
import { defineComponent, ref } from "vue";
import DeviceLora from "./DeviceLora.vue";
import DeviceSigfox from "./DeviceSigfox.vue";
import DeviceNbiot from "./DeviceNbiot.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
interface NewAddressData {
  tags: Array<string>;
}

export default defineComponent({
  name: "new-device-modal",
  components: {
    DeviceLora,
    DeviceSigfox,
    DeviceNbiot,
    Field,
    Form,
  },
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const newDeviceModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const targetData = ref<NewAddressData>({
      tags: [],
    });
    const rules = ref({
      tags: [
        {
          required: true,
          message: "Please Select Activity zone",
          trigger: "change",
        },
      ],
    });

    const submit = (value) => {
      console.log("Data from New Devicw Modal");
      return {
        //if(!value = '')
      };
    };

    return {
      targetData,
      submit,
      loading,
      formRef,
      rules,
      newDeviceModalRef,
    };
  },
});
